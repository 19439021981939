import React from "react"
import PropTypes from "prop-types"

const Message = ({ title, icon, children }) => (
  <div className="p-4 text-gray-700 bg-gray-100 shadow">
    <div className="flex items-center mb-4 space-x-2 opacity-25">
      {icon}
      {title && <span className="font-bold uppercase">{title}</span>}
    </div>

    {children}
  </div>
)

Message.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.object,
  children: PropTypes.any,
}

Message.defaultProps = {
  children: null,
  title: "Informatie",
  icon: (
    <svg
      className="w-12 h-12"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  ),
}

export default Message
