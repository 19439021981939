import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Message from "../components/message"
import Section from "../components/section"
import Mail from "../components/mail"

const About = ({ data }) => {
  const image = data?.image?.childImageSharp?.fluid

  return (
    <Layout>
      <SEO title="Over ons" />

      <Section>
        <article className="prose lg:prose-xl">
          <h1>Over ons</h1>

          <p>
            <b>Bogaerts Rik</b> heeft zich door de jaren heen gespecialiseerd
            in&nbsp;
            <u>permanente ontharing</u> met behulp van een erkend en
            doeltreffend apparaat. Het toestel VPL kent voor hem geen geheimen,
            wat met een ander toestel niet bereikt wordt kan hij door ervaring
            en know-how uw dromen waar maken. Of het nu gaat om die vervellende
            haren die net opduiken daar waar je het net niet wil of net dat
            vlekje waar jij je naadloos ergernissen over maakt elke dag.
          </p>

          <Message title="Vragen">
            Spreek er over op een gratis gesprek of mail je vraag gewoon
            naar&nbsp;
            <Mail /> en we nemen zo snel mogelijk contact op met je!
          </Message>

          <br />

          <Message title="Meer informatie">
            Voor meer informatie over wat er gebeurd bij de haarvermindering,
            bezoek onze <Link to="/info/vpl-light-treatment">info-pagina</Link>{" "}
            of stuur ons een mail voor meer informatie: <Mail />
          </Message>

          {image && <Img fluid={image} />}
        </article>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query AboutPageQuery {
    image: file(relativePath: { eq: "about-me.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default About
